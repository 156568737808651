import { Utils } from '../classes/utils';
import { Global } from '../classes/global';

export class FlxItemDetail {       //Database fields
	id:               string; //ID_ARTICULO
	reference_id:     string; //CODIGO_PRODUCTO
	barcode:          string; //CODIGOS_BARRA
	name:             string; //NOMBRE
	short_desc:       string; //DESCRIPCIONCORTA
	long_desc:        string; //DESCRIPCIONLARGA
	category:         string; //DESCRIPCION_CATEGORIA
	brand:            string; //DESCRIPCION_MARCA
	sizes:            any; //TALLES
	stock:            number; //STOCKTOTAL
	stock_local:      number; //STOCKTOTALDEPOSITO
	allow_neg_stock:  boolean; //PERMITESTOCKNEGATIVO
	coin_sym:         string; //SIMBOLOMONEDA
	coin_coefficient: number; //COTIZACIONMONEDA
	sell_price:       number; //PRECIOVENTA
	sale_price:       number; //PRECIOPROMOCION
	sale_fromDate:    string; //FECHAPROMOCIONDESDE
	sale_toDate:	  string; //FECHAPROMOCIONHASTA
	VAT_internal:     number; //MONTOTOTALII
	VAT_coefficient:  number; //COEFICIENTEIVA
	PACK_description: string; //DESCRIPCION_EMPAQUE
	PACK_coefficient: number; //COEFICIENTECONVERSION
	picture_url:      string; //FOTO
	thumbnail_url:    string; //FOTO
	updated:		  string; //FECHAMODIFICACION
	selected_size:    string; //TALLESELECCIONADO
	allow_fraction:   boolean;
	order_size_num:   number; //ORDENTALLE
	sizes_range:      string;  

	show_price: number;
	//Still not necessary
	//PESO
	//DESTACADOWEB
	//		this.updated		  = product.FECHAMODIFICACION;
	//		this.sale_fromDate    = product.FECHAPROMOCIONDESDE;
	//		this.sale_toDate	  = product.FECHAPROMOCIONHASTA;
	//		this.long_desc        = product.DESCRIPCIONLARGA;

	constructor(product: any) {
		if (product) {

			console.log("if product --");
			console.log(product);
			console.log(product[0].ID_ARTICULO);

			this.id               = product[0].ID_ARTICULO;
			this.reference_id     = product.CODIGO_PRODUCTO;
			this.barcode          = product.CODIGOS_BARRA;
			this.name             = Utils.toTitleCase(product.NOMBRE);
			this.short_desc       = Utils.capitalize(product.DESCRIPCIONCORTA || '');
	
			this.category         = Utils.toTitleCase(product.DESCRIPCION_CATEGORIA || '');
			this.brand            = Utils.toTitleCase(product.DESCRIPCION_MARCA || '');
			this.sizes              = (product.TALLES) ? product.TALLES.split('|') : '';
			if(product.TALLES){
				this.sizes_range = 'del ' + this.sizes[0] + ' al ' + this.sizes[this.sizes.length - 1];
			}
			this.stock            = product.STOCKTOTAL || 0;
			this.stock_local      = product.STOCKTOTALDEPOSITO || 0;
			this.allow_neg_stock  = (product.PERMITESTOCKNEGATIVO) ? (product.PERMITESTOCKNEGATIVO == 1) : false;
			this.coin_sym         = product.SIMBOLOMONEDA;
			
			this.sell_price       = product.PRECIOVENTA;
			this.sale_price       = product.PRECIOPROMOCION || 0;

			this.VAT_internal     = product[0].MONTOTOTALII || 0;	
			this.VAT_coefficient  = product[0].COEFICIENTEIVA || 1;
			this.PACK_description = Utils.capitalize(product.DESCRIPCION_EMPAQUE || '');			
			this.PACK_coefficient = product.COEFICIENTECONVERSION || 1;
			
			this.coin_coefficient = product.COTIZACIONMONEDA || 1;
			this.allow_fraction   = (product.FRACCIONADO) ? (product.FRACCIONADO == 1) : false;
			this.picture_url      = Global.API_ENDPOINT + product.FOTO;
			this.thumbnail_url    = this.picture_url + '?thumb=300x300';
	

			this.selected_size   = product.TALLESELECCIONADO;
			this.order_size_num  = product.ORDENTALLE;
		}
	}

	public getQuantity(qty) {
		if(this.PACK_coefficient != 0){
			qty = Math.ceil(qty / this.PACK_coefficient) * this.PACK_coefficient; 
		}
		return qty;
	};	

	public formatQuantity(qty) {
        return qty.toFixed((this.allow_fraction) ? 3 : 0);
	};	

	public packQuantity(qty) {
		let packQty: string = '';
		
		if((this.PACK_description != '') && (this.PACK_coefficient != 0)) {
			packQty = this.formatQuantity(qty / this.PACK_coefficient) + ' ' + this.PACK_description; 	
		}

        return packQty;
	};		

	public getPrice() {
		if(this.sale_price > 0){			
			return this.sale_price;
		}			
		else {
			return this.sell_price;
		}
	}	
}