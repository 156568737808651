
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable }        from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer }          from '../models/customer';
import { State }             from '../models/state';
import { Utils }       from '../classes/utils';
import { Page }         	 from '../models/page';
import { Global } 			 from '../classes/global';

@Injectable()
export class CustomerService {
	
	constructor(private http: HttpClient) {
	}
	
	private extractArray(res: any) {
		return res.Customers.map((customer) => new Customer(customer));
	}

	private extractElement(res: any) {
		return (res.Customer) ? new Customer(res.Customer) : null;
	}

	private extractCount(res: any) {
		return (res.totalcount) ? res.totalcount : null;
	}

    //'&fields=CODIGOCLIENTE,CODIGOPARTICULAR,RAZONSOCIAL,DIRECCION,TELEFONO,EMAIL,DOCUMENTO,CUIT,ACTIVO'; //By ID, DNI, CUIT, Razón Social, Nombre de Fantasía	
	searchCustomers(query: string, page: Page): Observable<Customer[]> {
		return this.http.get(Global.API_ENDPOINT_V + '/customers?'+Utils.paginate(page)+'&search=' + encodeURIComponent(query)).pipe(
			map(this.extractArray)
			,catchError(this.handleError));
	}

	searchCustomersCount(query: string): Observable<number> {
		return this.http.get(Global.API_ENDPOINT_V + '/customers/count?search=' + encodeURIComponent(query)).pipe(
			map(this.extractCount)
			,catchError(this.handleError));		
	}

	private mapStates(res:any) {
		return res.States.map((state)=>new State(state));
	}
	
	getStates(query: string):Observable<State[]>{
		return this.http.get(Global.API_ENDPOINT_V + '/states?search=' + encodeURIComponent(query)).pipe(
			map(this.mapStates)
			,catchError(this.handleError));		
	}

    findCustomer(id: string): Observable<Customer> {
		return this.http.get(Global.API_ENDPOINT_V + '/customers/' + encodeURIComponent(id)).pipe(
			map(this.extractElement)
			,catchError(this.handleError));
	}

	private handleError(error: any) {
		let errMsg: string;

		if(error.message){
			errMsg = error.message;
		}	
		else {
			errMsg = (error.message) ? error.message :
				error.status ? `${error.status} - ${error.statusText}` : 'Server error. Verifique que posee conexión a la red.';
		}
		return observableThrowError(errMsg);
	}


}
