
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlxItemDetail } from '../models/order_flx_item_detail';
import { PaymentMethod } from '../models/paymentMethod';
import { OrderFlxItem } from '../models/order_flx_item';
import { OrderFlx } from '../models/order_flx';
import { Global } from '../classes/global';


@Injectable()
export class FlxOrderService {
    
    
    products: Array<OrderFlxItem>;  
    totalPriceCart: number;

    subtot:string;
    modif:string;
    totalOrderPrice:string;

    payments:PaymentMethod[];
    paymentMethod:PaymentMethod;

    disscount = false;
    recharge = false;

    constructor (private http:HttpClient) {        
        this.products = [];
        this.totalPriceCart = 0;

      
    }

/////////////// TODO: Hace POST FINAL Sincroniza ordenes hacia FLEXXUS

    sendCart(order: OrderFlx): Observable<number> {
        let endpoint = "http://192.168.121.152:5000/v2"; // Usarlo para pruebas locales
        let cart = order

        
        console.log("\n|*|*|*|*| DATA INSERTA ORDEN en FLEXXUS |*|*|*|*|");
        console.log(cart);

/////////////// TODO: PARA TEST //
        //return void(0);

         return this.http.post<any>(endpoint + "/orders/",{cart}).pipe(
        map(res=>{return res})
        ,catchError(this.handleError));  
}
/////////////// TODO: PARA PROD // 

/*           
		return this.http.post<any>(Global.API_ENDPOINT + "/orders/",{cart}).pipe(
            map(res=>{return res.orderId})
            ,catchError(this.handleError));
    } */
    
    getSubtot(){
        let subtotal = this.totalPriceCart;

        if(this.paymentMethod && this.paymentMethod.hiddenvalue != 0){
            subtotal +=  this.totalPriceCart * this.paymentMethod.hiddenvalue / 100 ;
        }
        return subtotal.toFixed(2);

    }

    getModif(){
        if(this.paymentMethod && this.paymentMethod.value != 0){
            return Math.abs((this.totalPriceCart - (this.totalPriceCart + this.totalPriceCart * this.paymentMethod.value / 100 ))).toFixed(2);
        }
        else
            return "0";
    }


    getPayments(query: string):Observable<PaymentMethod[]>{
            
        return this.http.get<any[]>("").pipe(
        map(this.mapPayments)
        ,catchError(this.handleError));		
    }
    
    private handleError(res: any) {
		let errMsg: string;

		if(res.error.message){
			errMsg = res.error.message;
		}	
		else {
			errMsg = (res.error.message) ? res.error.message :
				res.error.status ? `${res.error.status} - ${res.error.statusText}` : 'Server error. Verifique que posee conexión a la red.';
		}
		return observableThrowError(errMsg);
	}

	private mapPayments(data:any){
		return data.PaymentMethods.map((payment)=>new PaymentMethod(payment));
	}

    //The idea is always work with a memory array, but save its state, just because.
   

    private getTotalPrice(pItem: OrderFlxItem){
        let totalPrice: number;
        
        if (pItem.product.sale_price > 0)
            totalPrice = pItem.product.sale_price * pItem.quantity;
        else
            totalPrice = pItem.product.sell_price * pItem.quantity;

        return totalPrice;
    }

    public getTotalPriceCart(){
        let totalPriceCart: number = 0;
        
        for (let i = 0; i < this.products.length; i++)
            totalPriceCart += this.products[i].totalPrice * this.products[i].product.coin_coefficient;

        return totalPriceCart; 
    }

    public isEmpty() {
        return ((!this.products) || (this.products.length == 0));
    }

    applyPaymentMethod(payment:any){
        this.paymentMethod = payment || this.paymentMethod
        
        this.totalPriceCart = 0;

        this.products.forEach(item=>{
            item.totalPrice = item.totalPrice;
        })

        this.totalPriceCart = this.getTotalPriceCart();

        this.subtot = this.getSubtot();
        this.modif = this.getModif();
        this.totalOrderPrice = this.getTotalOrderPrice();     
        
        if(parseFloat(this.subtot)>parseFloat(this.totalOrderPrice)){
            this.disscount = true;
            this.recharge = false;
        }
        if(parseFloat(this.subtot)<parseFloat(this.totalOrderPrice)){
            this.disscount = false;
            this.recharge = true;
        }
        
    }
    

    getItemPrice(item:OrderFlxItem){
        if(this.paymentMethod && this.paymentMethod.hiddenvalue != 0){
            return ((item.totalPrice/item.quantity) + (item.totalPrice/item.quantity) * this.paymentMethod.hiddenvalue / 100).toFixed(2); 
        }
        else
        return (item.totalPrice/item.quantity).toFixed(2)
    }
    
    getTotalItemPrice(item:OrderFlxItem){
        if(this.paymentMethod && this.paymentMethod.hiddenvalue != 0){
            return (item.totalPrice + item.totalPrice * this.paymentMethod.hiddenvalue / 100).toFixed(2); 
        }
        else
            return item.totalPrice.toFixed(2)
    }

    getTotalOrderPrice(){
        let total = this.totalPriceCart;

        if(this.paymentMethod && this.paymentMethod.hiddenvalue != 0){
            total +=  this.totalPriceCart * this.paymentMethod.hiddenvalue / 100 ;
        }
        if(this.paymentMethod && this.paymentMethod.value != 0){
            total +=  this.totalPriceCart * this.paymentMethod.value / 100 ;
        }
            return total.toFixed(2);
    }
}