
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable }        from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderMeli } from '../models/order_meli';
import { config_MELI } from '../models/config';

@Injectable()
export class MeliOrderService {
    
    
	constructor(private http: HttpClient) {
	}
    
    
    public getOrders():Observable<OrderMeli[]>{
        return this.http.get(config_MELI.api_root_url+"/orders/search?seller="+localStorage.getItem("meli_user_id")+"&order.status=paid&sort=date_desc&access_token="+localStorage.getItem("meli_access_token")).pipe(
		map(this.extractArray)
		,catchError(this.handleError))
    }

	private extractArray(res: any) {
		return res.results.map(order=>new OrderMeli(order))
	}

	private extractElement(res: any) {
		return res
	}

	private handleError(error: any) {
		let errMsg: string;

		if(error.message){
			errMsg = error.message;
		}	
		else {
			errMsg = (error.message) ? error.message :
				error.status ? `${error.status} - ${error.statusText}` : 'Server error. Verifique que posee conexión a la red.';
		}
		return observableThrowError(errMsg);
	}


}
