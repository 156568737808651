
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config_FlxIntegrate } from '../models/config';
import { RelationsItems } from '../models/relations_items';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Global } from '../classes/global';
import { Translate }       from '../classes/translate';

@Injectable()
export class FlxIntegrateService {
  api_root_url: string;

  constructor(private http: HttpClient) {
    this.api_root_url = config_FlxIntegrate.api_root_url;
  }

  public searchDestinationId(searchString,integrationId)
  {
    let apiHeader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .get(this.api_root_url + '/searchinorigin/' + searchString + "&" + integrationId +'&'+ Global.DATABASE_CLIENT, {
        headers: apiHeader
      }).pipe(
      map(this.extractArray));
      
  }

  public postProducts(origin_id, service_id) {

    console.log(origin_id);

    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    var body_array = {
      origin_id: origin_id,
      database: localStorage.getItem("cnn_relation_db"),
      id_service: service_id
    };


    return this.http
      .post(this.api_root_url + '/item', JSON.stringify(body_array), {
        headers: apiHeader
      }).pipe(
      map(res=>res)
      ,catchError(this.handleError)
      )
   
  }

  public putRelationsProducts(id_relation,integration_id,model_id,origin_id,destination_id):Observable<any>{
    // this.action = "publicar"; MLA726148150

    // let id_relation = id_relation; // Este dato debería ser dinámico
    let apiHeader = new HttpHeaders().set('Content-Type', 'application/json');

    if (!integration_id) integration_id = 1;

    if (!model_id) model_id = 1;

    var body_array = {
      integration_id: integration_id,
      model_id: model_id,
      origin_id: origin_id,
      destination_id: destination_id,
      id_relation: id_relation,
      database: Global.DATABASE_CLIENT
    };

    //return this.http.put(this.api_root_url+'/relationrel/'+id_relation,JSON.stringify

    return this.http
      .post(this.api_root_url + '/relationrel/', JSON.stringify(body_array), {
        headers: apiHeader
      }).pipe(
      map(res=>res)
      ,catchError(this.handleError)
      )
      
  }

  public getRelationsProducts(origin_id) {
    let apiHeader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .get(this.api_root_url + '/originrelation/' + origin_id + '&'+ Global.DATABASE_CLIENT, {
        headers: apiHeader
      }).pipe(
      map(this.extractElement));
  }

  public getRelationsDestinationId(destination_id) {
    let apiHeader = new HttpHeaders().set('Content-Type', 'application/json');

    console.log('-- getRelationsDestinationId --')
    
    return this.http
      .get(this.api_root_url + '/destination/'+destination_id + '&'+ Global.DATABASE_CLIENT, {
        headers: apiHeader
      }).pipe(
      map(this.extractElement2)
      ,catchError(this.handleError)
      );
      
  }

  public getAllRelations() {
    let apiHeader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .get(this.api_root_url + '/relationrel/' + Global.DATABASE_CLIENT , {
        headers: apiHeader
      }).pipe(
      map(this.extractArray2)
      ,catchError(this.handleError)
      );
  }

  public unlinkArticle(id_relation){
    let apiHeader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .delete(this.api_root_url + '/relationrel/'+ id_relation + "&" + Global.DATABASE_CLIENT , {
        headers: apiHeader
      }).pipe(
      map(res => res)
      ,catchError(this.handleError)
      );
  }

  postOrderMeliFlx(flxId,meliId):Observable<any>{
    let relation = {"destination_id":flxId,"origin_id":meliId,"accomplished":1,"database_name":Global.DATABASE_CLIENT}
    return this.http.post(this.api_root_url+"/relation_order",relation).pipe(
    map(res=>{return res})
    ,catchError(this.handleError)
    )
  }

  getOrderMeliFlx():Observable<any>{
    console.log("GET RELATION ORDER")
    return this.http.get(this.api_root_url+"/relation_order/"+ Global.DATABASE_CLIENT).pipe(
    map(res=>{return res})
    ,catchError(this.handleError)
    )
  }

  private extractElement(res: any) {
    return res.data[0] ? new RelationsItems(res.data[0]) : null;
  }

  private extractElement2(res: any) {
    return res.data ? new RelationsItems(res.data) : null;
  }

  private extractArray(res: any) {
    console.log(res);
    return res.data.map(relation => new RelationsItems(relation));
  }

  private extractArray2(res: any) {
    console.log(res);
    return res.map((relation) => new RelationsItems(relation)
    );
  }

  private handleError(error: any) {
    
    let errMsg: string;
    
    console.log('ERRORES');
    console.log(error.error.message.message);
    console.log(error.error.message.cause[0].message);

		if(error.error.message.message){
      errMsg = Translate.translate(error.error.message.message);
    } 
      
    if (error.error.message.cause[0]) {
        errMsg = errMsg + '<br>' + Translate.translate(error.error.message.cause[0].message)
      }       
    
    /* else {
			errMsg = (error.message) ? error.message :
				error.status ? `${error.status} - ${error.statusText}` : 'Server error. Verifique que posee conexión a la red.';
		}  */
		return observableThrowError(errMsg);
	}


}
