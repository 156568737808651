export class Config {
  warehouse_id: string;
  price_list: string;
  database_hash: string;
  cart_type: string;
  attribute_group: string;
  product_char_count: string;
  size_char_count: string;
  

  constructor(config: any) {
    if (config) { Object.assign(this, config); }
  }
}
// Datos API MERCADOLIBRE
// https://developers.mercadolibre.com.ar/apps/home/
// "nickname": "TESTPGW45LTA","password": "qatest6854",

export let config_MELI = {


  // API TEST  LOCALHOST
/*    client_id: '5627632262673167',
  secret_key: 'UnEjfWCQXiG1wVowYKtNZJjjB4H2ursU',  
  redirect_uri: 'http://localhost:4200/files/integrations',  */

// API TEST PRODUCCION CGA
/*   client_id: '7559529900142373',
  secret_key: 'GTSuFcTngv1aYAgwkgRn9hDwMdODb2eo',  
  redirect_uri: 'https://integrador.mitiendaonline.com/files/integrations', */

    // LOCALHOST ENTERPRISE INTEGRADOR
/*     client_id: '3672130686948413',
    secret_key: 'NxNML45RGLytsGc0hn0dnNxCXJuSJ9XN',  
    redirect_uri: 'http://localhost:4200/files/integrations', */
  

// PRODUCCION ENTERPRISE INTEGRADOR Request Limitados
/*   client_id: '2189962971708303',
  secret_key: 'sVu238Qnoi5e2OeUJV3rnq9zkVAOdtkz',
  redirect_uri: 'https://integrador.mitiendaonline.com/files/integrations', */

  // PRODUCCION ENTERPRISE INTEGRADOR 
/*   client_id: '947089463481105',
  secret_key: 'cXXtI8oPcf8wzJ81KfeRqnLh743DF8ci',
  redirect_uri: 'https://test.integrador.mitiendaonline.com/files/integrations', */

  // TEST PRODUCCION ENTERPRISE INTEGRADOR
  client_id: '8867720728571035',
  secret_key: 'vyM9D4LIfVxo8SZ6vQkUIcICAM8Loq5v',
  redirect_uri: 'https://test.integrador.mitiendaonline.com/files/integrations',



  api_root_url: 'https://api.mercadolibre.com',
  skd_version: '0.0.1',
  auth_url: 'https://auth.mercadolibre.com/authorization',
  oauth_url: 'https://api.mercadolibre.com/oauth/token',


};

export let config_FlxIntegrate = {
  api_root_url: 'https://devnode1.mitiendaonline.com:4000',
  //api_root_url: 'https://devnode1.mitiendaonline.com:4001',

  // SYNCRHO ALL //

   modeSinchroAll: '&mode=p'  // mode=t TEST // mode=p PRODUCCION */

};

export let ConsoleLog = {

  status: 0 // 1 Muestra console.log | 0 No Muestra console.log

};
