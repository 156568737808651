import { Translate } from '../../app/classes/translate';

export class ItemMeli {
    id: string;
    price: number;
    available_quantity: number;
    initial_quantity: number;
    status: string;
    sub_status: string;

    constructor(item: any) {
		if (item) {
      this.id = item.id;
      this.price = item.price;
      this.available_quantity = item.available_quantity;
      this.initial_quantity = item.initial_quantity;
      this.status = Translate.translate(item.status);
      this.sub_status = Translate.translate(item.sub_status);
		}
    }
    
}