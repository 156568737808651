import {map , catchError} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { config_FlxIntegrate } from "../../models/config";
import { Global } from '../../classes/global';

@Injectable()

export class RelationsProvincesService {


  constructor(private http: HttpClient) { }

  public getProvinceExists(origin_id:number){
  

    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");


    return this.http
      .get(config_FlxIntegrate.api_root_url + '/province_exist/' + Global.DATABASE_CLIENT + '&'+ origin_id , {
        headers: apiHeader
      }).pipe(
      map(this.extractElement)
      ,catchError(this.handleError));
  }

  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);
  }
 
  private extractElement(res: any) {
    return res || null;
  }


}