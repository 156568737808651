
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { config_FlxIntegrate } from "../../models/config";
import { Global } from "../../classes/global";

@Injectable()
export class FlxWarehouses {


port: string = localStorage.getItem("port");
ipApiFlx: string = localStorage.getItem("ip_api_flx");

constructor(private http: HttpClient) {}


public Warehouses(accessToken){

    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    this.port ? this.port : this.port="null";

    return this.http
        .get(config_FlxIntegrate.api_root_url+"/warehouses/ip_api="+this.ipApiFlx+"&ver="+Global.VERSION+"&port="+ this.port +"&token="+accessToken).pipe(      
        map(res=>res)
        ,catchError(this.handleError));

}

private handleError(error: any) {

    let errMsg: string;

    if (error.message) {
        errMsg = error.message;
    } else {
        errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);

}

}