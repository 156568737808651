import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import { TestsComponent } from './theme/files/tests/tests.component';

// AGREGADOS MTO //
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS} from '@angular/common/http';

import { TokenInterceptor } from './classes/token.interceptor'

import { Auth } from './providers/auth_service'
import { CustomerService } from './providers/customer_service';
import { ProductRoutingModule } from './theme/files/products/product-routing.module';
import { ProductService } from './providers/product_service';
import { FlxIntegrateService } from './providers/flx_integrate_service';

import { ToastrModule } from 'ngx-toastr';

import { NumberFormatPipe }     from '../app/classes/number.pipe';
import { DepositoService } from './providers/deposito_service';
import { ParametersService } from './services/api_integrator/parameters_service';
import { SynchroallService } from './services/api_integrator/synchroall_service';


import { IpService } from './services/utilities/ip_service';
import { FlxWarehouses } from './services/api_flx/warehouses';
import { FlxOrderService } from './services/flx_order_service';


// INTEGRADOR SERVICES | PROVIDERS //

import { RelationsClientsService } from './services/api_integrator/relations_clients';

import { RelationsProvincesService } from './services/api_integrator/relations_provinces';

// MELI SERVICES | PROVIDERS //

import { MeliService } from './providers/meli_service';
import { MeliProductsService } from './providers/meli_products_service';
import { MeliUsers } from './services/api_meli/users';
import { MeliOrderService } from './services/meli_order_service';



@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    TestsComponent,
    NumberFormatPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    FormsModule,
    ToastrModule.forRoot()
  ],
  providers: [MenuItems,    
              Auth,
              CustomerService,              
              ProductService,
              MeliService,
              MeliProductsService,
              FlxIntegrateService,
              NumberFormatPipe,
              DepositoService,
              ParametersService,
              SynchroallService,
              IpService,
              {
                provide: HTTP_INTERCEPTORS,
                useClass: TokenInterceptor,
                multi: true
              },
              MeliOrderService,
              MeliUsers,
              RelationsClientsService,
              RelationsProvincesService,
              FlxWarehouses,
              FlxOrderService
            ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  
}
