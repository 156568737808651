import { ConsoleLog } from '../../models/config';


export class ViewConsoleLog {

    static consoleLog(data) {
        
        if(ConsoleLog.status==1)
        return console.log(data);

      }

}