import { Utils } from '../classes/utils';

export class User {
	username:		string;
	full_name: 		string;
	address:		string;
	company:		string;
	rol:			string;
	position:		string;
	email:			string;
	token:    		string;
	warehouse_id: 	string;
	warehouse_name:	string;	
	device_modules: any;
	
	constructor(user?: any) {
		if (user) {
			this.username = user.username;
			this.full_name = Utils.toTitleCase(user.full_name);
			this.address = Utils.toTitleCase(user.address);
			this.company = Utils.toTitleCase(user.company);
			this.rol = Utils.toTitleCase(user.rol);
			this.position = Utils.toTitleCase(user.position);
			this.email=user.email;
			this.token=user.token;
			this.warehouse_id=user.warehouse_id;
			this.warehouse_name = Utils.toTitleCase(user.warehouse_name);
			this.device_modules=user.device_modules;
		}
	}
}

export class User_Integration {
	cnn_relation_db: string;
	company_id: 	number;
	email:			string;
	full_name: 		string;
	ip_api_flx:		string;
	role_id:		number;
	service_id:		string;
	
	constructor(user_integration?: any) {
		if (user_integration) {
			this.full_name = Utils.toTitleCase(user_integration.full_name);
			this.email=user_integration.email;
			this.company_id=user_integration.company_id;
			this.role_id=user_integration.role_id;
			this.service_id=user_integration.service_id;
			this.ip_api_flx=user_integration.ip_api_flx;
			this.cnn_relation_db=user_integration.cnn_relation_db;
		}
	}
}