
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from "../../classes/global";
import { config_FlxIntegrate } from "../../models/config";

@Injectable()
export class ParametersService {
  constructor(private http: HttpClient) { }

  public postParameters(parameters): Observable<any> {
    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .post(config_FlxIntegrate.api_root_url+
        "/parameters",
        JSON.stringify(parameters),
        { headers: apiHeader }
      ).pipe(
      map(this.extractNumber)
      ,catchError(this.handleError));
  }

  public getParameters(service_id): Observable<any> {
    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .get(config_FlxIntegrate.api_root_url+
        "/paramsbyid/" +
          JSON.stringify(service_id),
        { headers: apiHeader }
      ).pipe(
      map(this.extractNumber)
      ,catchError(this.handleError));
  }

  public getParametersByName(service_id, parameters_name):Observable<any> {
    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .get(
        config_FlxIntegrate.api_root_url +
          "/paramsbyidname/" +
          service_id +
          "&" +
          parameters_name,
        { headers: apiHeader }
      ).pipe(
      map(res => res)
      ,catchError(this.handleError));
  }

  public getAccessToken(service_id) {
    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .get(config_FlxIntegrate.api_root_url + "/paramsbyidname/" + service_id + "&meli_access_token", {
        headers: apiHeader
      }).pipe(
      map(res=>res)
      ,catchError(this.handleError));
  }

  public getRefreshAccessToken(service_id) {
    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .get(config_FlxIntegrate.api_root_url + "/paramsbyidname/" + service_id + "&meli_refresh_access_token", {
        headers: apiHeader
      }).pipe(
      map(res=>res)
      ,catchError(this.handleError));
  }


  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);
  }

  private extractNumber(res: any) {
    return res.data || null;
  }
}
