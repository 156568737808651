import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {

  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  /*  {
    label: 'Navigation',
    main: [{
      state: 'navigation',
      short_label: 'N',
      name: 'Navigation',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'dashboards',
          short_label: 'D',
          name: 'Dashboard',
          type: 'sub',
          icon: 'ti-home',
          children: [
            {
              state: 'default',
              name: 'Default'
            },
            {
              state: 'ecommerce',
              name: 'Ecommerce'
            },
            {
              state: 'analytics',
              name: 'Analytics',
              badge: [
                {
                  type: 'info',
                  value: 'NEW'
                }
              ]
            }
          ]
        },
        {
          state: 'navigation',
          short_label: 'N',
          name: 'Navigation',
          type: 'link',
          icon: 'ti-layout-cta-right'
        },
        {
          state: 'widget',
          short_label: 'W',
          name: 'Widget',
          type: 'sub',
          icon: 'ti-view-grid',
          badge: [
            {
              type: 'danger',
              value: '100+'
            }
          ],
          children: [
            {
              state: 'statistic',
              name: 'Statistic'
            },
            {
              state: 'data',
              name: 'Data'
            },
            {
              state: 'chart',
              name: 'Chart'
            }
          ]
        }
      ],
    }],
  }, */
  /*  {
    label: 'Dashboard',
    main: [
      {
        main_state: 'files',
        state: 'dashboards',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home',
      }
    ]
  }, */
  {
    label: 'Products',
    main: [
      {
        main_state: 'files',
        state: 'products',
        short_label: 'P',
        name: 'Artículos',
        type: 'link',
        icon: 'ti-view-list-alt'
      }
    ]
  },
  {
    label: 'order',
    main: [
      {
        main_state: 'files',
        state: 'order',
        short_label: 'O',
        name: 'Pedidos',
        type: 'link',
        icon: 'ti-receipt'
      }
    ]
  },
  {
    label: 'Integrations',
    main: [
      {
        main_state: 'files',
        state: 'integrations',
        short_label: 'I',
        name: 'Integraciones',
        type: 'link',
        icon: 'ti-plug'
      }
    ]
  }
  /*   {
    label: 'Logs',
    main: [
      {
        main_state: 'files',
        state: 'logs',
        short_label: 'L',
        name: 'Logs',
        type: 'link',
        icon: 'ti-alert',        
      }
    ]
  },*/
];

@Injectable()
export class MenuItems {

/* 
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  order_menu: number;
  service_id: number;

  constructor(private parametersservice: ParametersService) {



    console.log('this.currentUser.service_id');
    console.log(this.currentUser.service_id);

    this.parametersservice.getParametersByName(this.currentUser.service_id, 'order_menu')
    .subscribe( res => {


      console.log('res');
      console.log(res);

      if (res.data[0]) {
      console.log('res.................');
      console.log(res.data[0].value);


      this.order_menu = res.data[0].value;

      MENUITEMS.splice(1,0,
        {
          label: 'order',
          main: [
            {
              main_state: 'files',
              state: 'order',
              short_label: 'O',
              name: 'Pedidos',
              type: 'link',
              icon: 'ti-receipt',
            }
          ]
        }
      );
      
      }

    }); 

  } */



  getAll(): Menu[] {

      return MENUITEMS;
  }

}
