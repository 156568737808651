export class State {

	stateId: string;
	name: string;	

	constructor(state?:any) {
         this.stateId=state.CODIGOPROVINCIA;
         this.name=state.NOMBRE;
	}
}

