
import {map , catchError} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { config_MELI } from "../models/config";
import { Observable, TimeoutError } from "rxjs";
import { RelationsItems } from "../models/relations_items";
import { Product } from "../models/product";
import { FlxIntegrateService } from "./flx_integrate_service";
import { ProductService } from "./product_service";
import { Utils }       from '../classes/utils';

import { Global } 			 from '../classes/global';

@Injectable()
export class MeliProductsService {
  api_root_url: string;
  //rowsRelationsItems: RelationsItems[];
  //rowsProducts: Product[];
  synchronizing: number = 0;
  //totalToSynchronize: number = 0;
  constructor(
    private http: HttpClient,
    private FlxIntegrateService: FlxIntegrateService,
    private ProductService: ProductService
  ) {
    this.api_root_url = config_MELI.api_root_url;
  }

  public putProducts(destination_id, precioventa, stock, useVariantsAndAttributes) {

    precioventa = Utils.toStringParseFloat(precioventa);
    

    let api_root_url = this.api_root_url;
    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");
    let meli_token = localStorage.getItem("meli_access_token");

    if (stock > 0) {
      var status = "active";
    } else {
      var status = "paused";
      stock = 0;
    }

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser = currentUser['service_id'];

   
    var body_array_sin_talle = {
      price: precioventa,
      available_quantity: stock
      //"status": status
    };
 
    // Este caso lo usa Litoral Baby
    var body_array_con_talle = {
       status: status,
    };

    let body_array;

    if (useVariantsAndAttributes)
    {      
      body_array = body_array_con_talle;
    }
    else 
    {
      body_array = body_array_sin_talle;
    }
 

    //return this.http.put(api_root_url+'/items/'+MLA,JSON.stringify(body_array),{headers: apiHeader})
    //.map((res: Response) => res.json());

    //console.log("PRECIO VENTA A MELI");
    //console.log(precioventa);

    
    

      return this.http
      .put(
        "https://api.mercadolibre.com/items/" +
          destination_id +
          "?access_token=" +
          meli_token,
        JSON.stringify(body_array),
        { headers: apiHeader }
      ).pipe(
      map(res => res));

    
/*     else
    {
      console.log("No Entra")
      let itemToReturn = new Observable();
      return itemToReturn ;
    } */
   
  }


  // public putAllProducts() {

  //   this.synchronizing=1;    
  //   //this.totalToSynchronize=0;

  //   this.FlxIntegrateService.getAllRelations().subscribe(
  //     (items: RelationsItems[]) => {
  //       //console.log("-- getAllRelations --");
  //       //console.log(items);

  //       let filtrado = [];
  //       let items_truncate = [];
  //       let init = 0;
  //       let end = 200;



  //       //let relation_item = RelationsItems[0];

  //       let total_items = items.length;
  //       let divide_items = Math.round(total_items / 200);

  //       if (divide_items == 0) divide_items = 1;

  //       for (var i = 0; i < divide_items; i++) {

        
  //         items_truncate = items.slice(init, end);

  //         filtrado = items_truncate.filter(x => {
  //           return x.destination_id != "";
  //         });

  //         let ids = [];

  //         filtrado.forEach(x => {
  //           let item: RelationsItems;
  //           item = x;
  //           ids.push(x.origin_id);
  //         });

  //         if (ids.length > 0) {
  //           this.ProductService.getProductsIds(ids).subscribe(
  //             (Product: Product[]) => {
  //               Product.forEach(y => {

  //               let totalToSynchronize=0;

  //                 let encontrado = items.find(x => {
  //                   return x.origin_id == y.id && x.destination_id != "";
  //                 });

  //                 console.log(Product.length);
  //                 console.log('Synchronize');
  //                 console.log(total_items);
  //                 console.log(totalToSynchronize);

  //                 let stock = (Global.typeOfStock == '0')? y.stocktotal: y.stocktotaldeposito


  //                 this.putProducts(
  //                   encontrado.destination_id,
  //                   y.precioventa,
  //                   stock
  //                 ).subscribe(
  //                   confirm => {
  //                   totalToSynchronize++;
  //                   console.log('Synchronize Ok');
  //                   console.log(totalToSynchronize);
  //                     //console.log("Sincro Put Prodcuts")
  //                     //console.log(confirm);
  //                   },
  //                   err => {
  //                   totalToSynchronize++;
  //                   console.log('Synchronize Err');
  //                   console.log(totalToSynchronize);
  //                   }

                    
  //                 );

  //                 //console.log(encontrado);
  //                 /* console.log(
  //                   y.id +
  //                     " | " +
  //                     y.codigoproducto +
  //                     " | " +
  //                     encontrado.destination_id +
  //                     " | " +
  //                     y.precioventa +
  //                     " | " +
  //                     y.stocktotaldeposito
  //                 ); */

  //                 if (Product.length==totalToSynchronize)
  //                 this.synchronizing=0;

  //               });
  //             }
  //           );
  //         }
  //         init = end;
  //         end = init + 200;

  //        this.synchronizing=0;
          
  //       }

  //     }
  //   );
  // }
}
