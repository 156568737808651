import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';


import { Observable } from 'rxjs';
import { Global } from './global';
import { ViewConsoleLog } from '../services/utilities/console_log';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( (request.url!='https://api.mercadolibre.com/oauth/token') && (request.url!='https://api.ipify.org?format=json'))
    {
      //ViewConsoleLog.consoleLog("Bearer");
      ViewConsoleLog.consoleLog(Global.TOKEN);

    request = request.clone({
      setHeaders: {
        Authorization: "Bearer " + Global.TOKEN
    
      }
    });
    
  }
  return next.handle(request);
  }
}
