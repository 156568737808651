import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';





import { Router } from '@angular/router';
import { User, User_Integration } from '../models/user';
import { Global } from '../classes/global';
import { Config } from '../models/config';
import { AuthResponse } from '../models/AuthResponse';

import { ToastrService } from 'ngx-toastr';

import { Utils }       from '../classes/utils';
import { MeliRefreshToken } from '../services/api_meli/refresh_token';
import { ParametersService } from '../services/api_integrator/parameters_service';
import { IpService } from '../services/utilities/ip_service';

@Injectable()
export class Auth {
	public loggedIn: boolean;
	public currentUser: User_Integration;
	public currentUser_FLX: User;
	public currentConfigFlx: Config;
	private melirefreshtoken:MeliRefreshToken;

    constructor(private http: HttpClient, private router:Router, private parametersservice:ParametersService, private ipservice:IpService,
		private toastr: ToastrService) {
		
		
		//Global.API_ENDPOINT = this.addHttp(Global.SERVER) + Global.API_VERSION;
		//Global.API_ENDPOINT = Global.SERVER + Global.API_VERSION;
		let user = JSON.parse(localStorage.getItem('currentUser'));
		Global.TOKEN = localStorage.getItem('flx_access_token');

		this.loggedIn = !(user === null);				
		if(this.loggedIn){
			this.currentUser = new User_Integration(user);
		}

	}


// AUTHENTICATIONS

	// Authenticate API INTEGRATION

	 auth_API_INTEGRATOR(server, email, password, error, result) {
		
		//Global.API_ENDPOINT = this.addHttp(server) + Global.API_VERSION;

		//let config:Config;

		var body = { 
			"email": email,
			"passwrd":password 
			}; 

		this.http.post<any>(Global.API_ENDPOINT_INTEGRADOR + Global.APIFLX_USER_LOGIN,JSON.stringify(body), {headers: new HttpHeaders().set('Content-Type', 'application/json')})
		.subscribe(
			response => {
				if (response.token != undefined){


					console.log('OK');
					console.log(response.token);
					
					this.toastr.success('Ingresando...','Autenticación correcta',{

						timeOut: 1500,
						closeButton: true
					  });

					let IpPublicDns;
					let IpPrivate;
					let IpPublicLocalhost;
					//localStorage.setItem('users_meli_ids', response.data.users_meli_ids);
/* 
					

					this.parametersservice.getParametersByName(response.data.service_id,'ip_private')
					.subscribe( res => {
						console.log('IpPrivate');
						IpPrivate = res.data[0].value;
						console.log(IpPrivate);
					})

					this.ipservice.getPublicIP().subscribe( res => {

						IpPublicLocalhost = res['ip'];
						console.log(IpPublicLocalhost);
						console.log(response.data.ip_api_flx);
					}) */
					
					/* if (IpPublicDns=IpPublicLocalhost)
					{
						localStorage.setItem('ip_api_flx', IpPrivate);
					}
					else
					{
						
					} */

					this.parametersservice.getParametersByName(response.data.service_id,'warehouse_list')
					.subscribe( res => {

						if (res.data[0])
						localStorage.setItem('warehouse_list', res.data[0].value);
					}) 

					this.parametersservice.getParametersByName(response.data.service_id,'protocol')
					.subscribe( res => {

						if (res.data[0])
						localStorage.setItem('protocol', res.data[0].value);
					})

					this.parametersservice.getParametersByName(response.data.service_id,'port')
					.subscribe( res => {

						if (res.data[0])
						{
						localStorage.setItem('port', res.data[0].value);
						res.data[0].value ? Global.PORT = res.data[0].value : Global.PORT = "''";
						}

					})



					localStorage.setItem('ip_api_flx', response.data.ip_api_flx);
					localStorage.setItem('cnn_relation_db', response.data.cnn_relation_db);

					Global.SERVER=response.data.ip_api_flx;
					Global.DATABASE_CLIENT=response.data.cnn_relation_db;
					Global.API_ENDPOINT_V = Global.SERVER + Global.API_VERSION;

					//response.user.email = email;
					this.currentUser = new User_Integration(response.data);
					
					/* this.melirefreshtoken.getRefreshAccessToken(this.currentUser.service_id).subscribe(
						res => {
					console.log(res);
						}); */
 
					//config = response.data.config;
					this.logIn(this.currentUser);
					result();

				}
			},
			response => {

				console.log('--APIFLX INTEGRATION AUTH ERR--');

				if (response.status == 401)
					error(response.message);
				else if (response.name == 'TimeoutError')	
					error('No se pudo encontrar el servidor. Verifique su conexión');
				else if (response.statusText == '')						
					error('Error al conectarse con el servidor. Verifique que la ruta es correcta.');
				else
				console.log("ERROR LOGIN");
					error(response);

					this.toastr.error('Su usuario y/o contraseña no es válido','Autenticación incorrecta',{
						
						timeOut: 5000,
						closeButton: true,
						progressBar: true
					  });

			}
			
		);
	}

	public auth_APIFLX(server, user, password, error, result) {

		console.log('-- error Auth APIFLX --');
		console.log(error);
		console.log(result);
		console.log('-- error Auth APIFLX FIN--');

		let uuid = '4953457348957348975'; //Alternative uuid temporary enabled on the server for testing	
		
		console.log('-- server --');	
		console.log(server);

		Global.API_ENDPOINT_V = server + Global.API_VERSION;
		

		let deviceInfo = {
			model: '0',
			platform: '0',
			uuid: uuid,
			version: '0',
			manufacturer: '0',
		}

		
		let config:Config;

		/* var body:any = {};
		body.username = user;
		body.password = password;
		body.deviceinfo = JSON.stringify(deviceInfo); */ 
		
		let protocol = localStorage.getItem("protocol");
		let port: string;

		localStorage.getItem("port") ? port = ',"port":"' + localStorage.getItem("port") + '"' : port =',"port":""';

		console.log(port);

		//let port = '"port":"' + localStorage.getItem("port") + '"';

		let body = '{"username":"'+user+'","password":"'+password+'","protocol":"'+protocol+'","ip_api_flx":"'+server+'","service_id":"'+this.currentUser.service_id+'"'+port+',"ver":"'+Global.VERSION+'"}';

		console.log(body);

		body = JSON.parse(body);

		

		this.http.post<AuthResponse>(Global.API_ENDPOINT_INTEGRADOR + '/auth/login', body, {headers: new HttpHeaders().set('Content-Type', 'application/json')})		
		.subscribe(
			response => {
				if (response.token != undefined){
					
					Global.TOKEN = response.token;
					this.currentConfigFlx = new Config(response.config);

					//localStorage.setItem('currentUser', JSON.stringify(currentUser));
					localStorage.setItem('config', JSON.stringify(this.currentConfigFlx));		
					localStorage.setItem('flx_access_token', Global.TOKEN);		
					
					this.loggedIn = true;
					
					//config = response.config;
					this.logIn(this.currentUser);
					result();
				}
			},
			response => {
				if (response.status == 401)
					error(response.message);
				else if (response.name == 'TimeoutError')	
					error('No se pudo encontrar el servidor. Verifique su conexión');
				else if (response.statusText == '')						
					error('Error al conectarse con el servidor. Verifique que la ruta es correcta.');
				else
					error(response);
					
			}
			
		);	

	}


	logOut() {
		this.loggedIn = false;
		this.currentUser = null;

		//this.inventorySrv.inventoryId=-1;		
		console.log('LOGOUT');
		
		/* localStorage.removeItem('currentUser');
		localStorage.removeItem('isDebug');
		localStorage.removeItem('config');
		localStorage.removeItem('token');
		localStorage.remooveItem('token'); */

		localStorage.clear();
		
		Global.API_ENDPOINT_V = '';
		Global.TOKEN = "";	

        this.router.navigate(['auth']);
	}

	isLoggedIn() {
		return this.loggedIn;
	}

	public getToken(){
		return Global.TOKEN;
	}

	private logIn(currentUser: User_Integration) {

		///var stringDevices:string = JSON.stringify(currentUser.device_modules);
		//stringDevices=stringDevices.replace(/true/g,"\"true\"");
		//stringDevices=stringDevices.replace(/false/g,"\"false\"");
		//currentUser.device_modules=JSON.parse(stringDevices);
		
		localStorage.setItem('currentUser', JSON.stringify(currentUser));
		//localStorage.setItem('config', JSON.stringify(config));		
		localStorage.setItem('flx_access_token', Global.TOKEN);					
		//localStorage.setItem('api_endpint', Global.API_ENDPOINT);
		
		this.loggedIn = true;
	}

	private logIn_APIFLX(currentUser: User, server: string) {

		var stringDevices:string = JSON.stringify(currentUser.device_modules);
		stringDevices=stringDevices.replace(/true/g,"\"true\"");
		stringDevices=stringDevices.replace(/false/g,"\"false\"");
		currentUser.device_modules=JSON.parse(stringDevices);
		
		localStorage.setItem('currentUserFlx', JSON.stringify(currentUser));
		//localStorage.setItem('config', JSON.stringify(config));		
		localStorage.setItem('flx_access_token', Global.TOKEN);	

		this.loggedIn = true;
	}

	
	
}
