import { AddressMeli } from "./address";

export class MeliUserModel {
    address: AddressMeli[];
    id: number;
    nickname: string;

    constructor(user?:any) {
		if (user) {
      this.address = user.address;
      this.id = user.id;
      this.nickname = user.nickname;
		}
    }
    
}