import { OrderMeliItem } from './order_meli_item';
import { ClientMeli } from './client_meli';
import { ShippingMeli } from './shipping_meli';

export class OrderMeli {
  id: string;
  status: string;
  total_amount: number;

  buyer: ClientMeli;
  order_items: OrderMeliItem[];

  date_closed: string;
  date_created: string;
  date_last_updated: string;

  shipping: ShippingMeli;

  destination_id: string;

  constructor(order?: any) {
    if (order) {
      this.id = order.id;
      this.status = order.status;
      this.total_amount = order.total_amount;
      this.destination_id = order.destination_id
      
      this.buyer = order.buyer;

      if (order.buyer.phone) {
        order.buyer.phone.area_code
          ? (this.buyer.phone =
              '(' +
              order.buyer.phone.area_code +
              ') ' +
              order.buyer.phone.number)
          : (this.buyer.phone = order.buyer.phone.number);
      }

      this.order_items = order.order_items;
      this.date_closed = order.date_closed;
      this.date_created = order.date_created;
      this.date_last_updated = order.date_last_updated;

      this.date_closed = this.date_closed.split('T')[0];
      this.date_created = this.date_created.split('T')[0];
      this.date_last_updated = this.date_last_updated.split('T')[0];

      if (order.shipping) this.shipping = new ShippingMeli(order.shipping);
    }
  }
}
