export class Depositos{
    id_deposito: number;
    nombreDeposito: string;
    



constructor(depositos?:any){

    if(depositos){
        this.id_deposito = depositos.CODIGODEPOSITO;
        this.nombreDeposito =depositos.DESCRIPCION;
        
    }

}


}