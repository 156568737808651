import {map , catchError} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { config_FlxIntegrate } from "../../models/config";
import { Global } from '../../classes/global';

@Injectable()

export class RelationsClientsService {

  constructor(private http: HttpClient) { }

  public getClientExists(origin_id:number){

    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .get(config_FlxIntegrate.api_root_url + '/client_exist/' + Global.DATABASE_CLIENT + '&'+ origin_id , {
        headers: apiHeader
      }).pipe(
      map(this.extractElement)
      ,catchError(this.handleError));
  }

  public postClientOriginId(origin_id:number){
    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    var body_array = {
      origin_id: origin_id,
      destination_id: '',
      accomplished: null,
      database_name: localStorage.getItem("cnn_relation_db")
    };

    return this.http
      .post(config_FlxIntegrate.api_root_url+
        "/relation_clients",
        JSON.stringify(body_array),
        { headers: apiHeader }
      ).pipe(
      map(this.extractElement)
      ,catchError(this.handleError));
  }

  public putClientUpdate(id, destination_id){

    console.log("putClientUpdate");
    console.log(id);
    console.log(destination_id);

    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    var body_array = {
      id: id,
      destination_id: destination_id,
      accomplished: 1,
      database_name: localStorage.getItem("cnn_relation_db")
    };

    return this.http
      .put(config_FlxIntegrate.api_root_url+
        "/client",
        JSON.stringify(body_array),
        { headers: apiHeader }
      ).pipe(
      map(this.extractElement)
      ,catchError(this.handleError));
  }
   
  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);
  }

  private extractElement(res: any) {
    return res || null;
  }
}