
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { config_MELI } from "../models/config";
import { UserMeli } from "../models/usermeli";
import { ItemMeli } from "../models/item_meli";

import { ViewConsoleLog } from '../services/utilities/console_log';

interface Authorize {
  access_token: string;
  refresh_token: string;
  user_id: string;
}
@Injectable()
export class MeliService {
  api_root_url: string;
  client_id: string;
  secret_key: string;
  str_access_token: string;
  access_token: string;
  redirect_uri: string;
  refresh_token: string;
  constructor(private http: HttpClient) {
    this.api_root_url = config_MELI.api_root_url;
    this.client_id = config_MELI.client_id;
    this.secret_key = config_MELI.secret_key;
    this.redirect_uri = config_MELI.redirect_uri;
  }

  getAuthURL() {
    var query = {
      response_type: "code",
      client_id: this.client_id,
      redirect_uri: this.redirect_uri
    };
    return config_MELI.auth_url + this.convertObjectToQueryString(query);
  }

  authorize(code: string) {
    let apiHeader = new HttpHeaders().set("Content-Type", "text/plain");
    return this.http
      .post<Authorize>(
        config_MELI.oauth_url,
        {
          grant_type: "authorization_code",
          client_id: this.client_id,
          client_secret: this.secret_key,
          code: code,
          redirect_uri: this.redirect_uri
        },
        { headers: apiHeader }
      ).pipe(
      map(data => data));
  }




  convertObjectToQueryString(obj): string {
    // Clone the object obj and loose the reference
    obj = Object.create(obj);
    if (!obj.access_token && this.access_token)
      obj.access_token = this.access_token;
    var result = "?";
    for (var i in obj) {
      result += i + "=";
      if (obj[i] != undefined) {
        if (Array.isArray(obj[i])) {
          result += obj[i].join() + "&";
        } else {
          result += obj[i] + "&";
        }
      }
    }
    if (result[result.length - 1] == "&") {
      result = result.substr(0, result.length - 1);
    }
    if (result == "?") result = "";
    return result;
  }

  getUsers(): Observable<UserMeli> {
    let api_root_url = this.api_root_url;

    return this.http
      .get(api_root_url + "/users/151197075").pipe(
      map(x => new UserMeli(x)));
  }

  getItemsId(idItem): Observable<any> {
    
    let api_root_url = this.api_root_url;
    let meli_token = localStorage.getItem("meli_access_token");

    if(meli_token)
    this.str_access_token = '?access_token='+meli_token;
    else
    this.str_access_token = '';
    
    ViewConsoleLog.consoleLog("Get Items Id");
    ViewConsoleLog.consoleLog(this.str_access_token);

    return this.http
      .get(api_root_url + "/items/" + idItem + this.str_access_token).pipe(
      map(x => new ItemMeli(x)));

    
  }

  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);
  }

}
