
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable }        from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Depositos } from '../models/depositos';
import { Global } 			 from '../classes/global';

@Injectable()
export class DepositoService {
	
	constructor(private http: HttpClient) {
	}
    getDepositos(query: string):Observable<Depositos[]>{
		return this.http.get(Global.API_ENDPOINT_V + '/warehouses?search=' + encodeURIComponent(query)).pipe(
			map(this.mapDepositos)
			,catchError(this.handleError));		
	}


    private mapDepositos(res:any) {
		return res.Warehouses.map((depositos)=>new Depositos(depositos));
    }
    
    private handleError(error: any) {
		let errMsg: string;

		if(error.message){
			errMsg = error.message;
		}	
		else {
			errMsg = (error.message) ? error.message :
				error.status ? `${error.status} - ${error.statusText}` : 'Server error. Verifique que posee conexión a la red.';
		}
		return observableThrowError(errMsg);
	}
}