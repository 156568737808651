import { map, catchError } from 'rxjs/operators';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { config_MELI } from '../../models/config';

import { MeliUserModel } from '../../models/meli/users';

@Injectable()
export class MeliUsers {
  api_root_url: string;

  constructor(private http: HttpClient) {
    this.api_root_url = config_MELI.api_root_url;
  }

  public UsersMe(accessToken): Observable<any> {
    let apiHeader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .get('https://api.mercadolibre.com/users/me?access_token=' + accessToken)
      .pipe(
        map(res => res),
        catchError(this.handleError)
      );
  }

  getUsers(id): Observable<MeliUserModel> {
    let api_root_url = this.api_root_url;

    return this.http
      .get(api_root_url + '/users/' + id)
      .pipe(map(x => new MeliUserModel(x)));
  }

  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error. Verifique que posee conexión a la red.';
    }
    return observableThrowError(errMsg);
  }
}
