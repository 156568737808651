import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { TestsComponent } from './theme/files/tests/tests.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [         
      {
        path: 'files',        
        loadChildren: './theme/files/files.module#FilesModule'
      }
      
    ]
  },
  {
    //path: '',
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: './theme/auth/login/basic-login/basic-login.module#BasicLoginModule',
      }
    ]
  },

  {
    //path: '',
    path: 'test',
    component: TestsComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
