
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Product } from "../models/product";
import { Utils } from "../classes/utils";
import { Page } from "../models/page";
import { Global } from "../classes/global";
import { FlxIntegrateService } from "../providers/flx_integrate_service";
import { ViewConsoleLog } from '../services/utilities/console_log';
import { FlxItemDetail } from "../models/order_flx_item_detail";
import { config_FlxIntegrate } from '../models/config';

@Injectable()
export class ProductService {
  headers: HttpHeaders;
  productsDetail: Product;
  warehouse_list: string;
  ip_api_flx: string;
  api_root_url: string;
  port: string = localStorage.getItem("port");

  constructor(
    private http: HttpClient,
    private flxitegrationservice: FlxIntegrateService,
    
  ) {
    this.headers = new HttpHeaders({ Authorization: "Bearer " + Global.TOKEN });

 
    this.port ? this.port : this.port="null";

    this.api_root_url = config_FlxIntegrate.api_root_url;
    this.ip_api_flx = localStorage.getItem("ip_api_flx");

  }

  ngOnInit() {

    this.ip_api_flx = localStorage.getItem("ip_api_flx");
  }


  
  private extractArray(res: any) {
    return res.Products.map(product => new Product(product));
  }

  private extractElement(res: any) {
    return res.Product ? new Product(res.Product) : null;
  }

  private extractElementFlx(res: any) {
    return res.data ? new FlxItemDetail(res.data) : null;
  }

  private extractCount(res: any) {
    return res.totalcount ? res.totalcount : null;
  }

  //'&fields=CODIGOCLIENTE,CODIGOPARTICULAR,RAZONSOCIAL,DIRECCION,TELEFONO,EMAIL,DOCUMENTO,CUIT,ACTIVO'; //By ID, DNI, CUIT, Razón Social, Nombre de Fantasía
 /*  searchProducts(query: string, page: Page): Observable<Product[]> {
    //let apiHeader = new HttpHeaders().set("Content-Type", "application/json");
    //apiHeader.set("Authorization: Bearer " + Global.TOKEN)

    // Warehouse <- Select specific 
    this.warehouse_list = localStorage.getItem("warehouse_list");
    
    return this.http
      .get(Global.API_ENDPOINT+
          "/products?warehouse_list="+ this.warehouse_list +"&"+
          Utils.paginate(page) +
          "&search=" +
          encodeURIComponent(query),
        { headers: this.headers }
      )
      .map(this.extractArray)
      .catch(this.handleError);
  } */

  searchProducts(query: string, page: Page): Observable<Product[]> {
    //let apiHeader = new HttpHeaders().set("Content-Type", "application/json");
    //apiHeader.set("Authorization: Bearer " + Global.TOKEN)

    ViewConsoleLog.consoleLog("searchProducts - product_service.ts")
    ViewConsoleLog.consoleLog(query);
    ViewConsoleLog.consoleLog(page);
    // Warehouse <- Select specific 
    this.warehouse_list = localStorage.getItem("warehouse_list");
    this.ip_api_flx = localStorage.getItem("ip_api_flx");

    query = (!query) ? "null" : encodeURIComponent(query) ;

    return this.http
      .get(Global.API_ENDPOINT_INTEGRADOR + "/products/warehouse_list="+ this.warehouse_list +"&"+Utils.paginate(page)+"&search="+ query + "&ip_api="+this.ip_api_flx+"&ver="+Global.VERSION+"&port="+ this.port +"&token="+Global.TOKEN,{headers:this.headers}).pipe(
      map(this.extractArray)
      ,catchError(this.handleError));
  }


  searchProductsCount(query: string): Observable<number> {
    return this.http
      .get(Global.API_ENDPOINT_V +
          "/products/count?search=" +
          encodeURIComponent(query),
        { headers: this.headers }
      ).pipe(
      map(this.extractCount)
      ,catchError(this.handleError));
  }

  findProduct(id: string): Observable<any> {
    ViewConsoleLog.consoleLog("ID: " + id);

    let endpoint = "192.168.121.152"; // Usarlo para pruebas locales
    // let endpoint = Global.API_ENDPOINT; // Usarlo para producción

    return this.http.get("http://"+ endpoint + ":" + localStorage.getItem("port") + "/" + Global.VERSION + "/products/" + encodeURIComponent(id),  
      {
        headers: this.headers
      }).pipe(
      map(this.extractElementFlx)
      ,catchError(this.handleError));
  }

  /* getProductsIds(ids: any[]): Observable<Product[]> {
    let stringsIds = "";

    ids.forEach(element => {
      stringsIds += element + ",";
    });

    // Warehouse <- Select specific 

    return this.http
      .get(
        Global.API_ENDPOINT + "/products?id=" + encodeURIComponent(stringsIds)+"&warehouse_list=" + this.warehouse_list,
        { headers: this.headers }
      )
      .map(this.extractArray)
      .catch(this.handleError);
  } */

  
  getProductsIds(ids: any[]): Observable<Product[]> {
  let stringsIds = "";

  ids.forEach(element => {
    stringsIds += element + ",";
  });

  // Warehouse <- Select specific 

  return this.http
    .get(Global.API_ENDPOINT_INTEGRADOR + "/products/ids/id="+ encodeURIComponent(stringsIds)+"&warehouse_list="+this.warehouse_list+"&ip_api="+Global.API_ENDPOINT+"&ver="+Global.VERSION+"&port="+ this.port +"&token="+Global.TOKEN,{headers:this.headers}).pipe(
    map(this.extractArray)
    ,catchError(this.handleError));
}

  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
          ? `${error.status} - ${error.statusText}`
          : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);
  }
}
