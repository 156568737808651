import CryptoJS from 'crypto-js';
import { Page } from '../models/page';
import { Global } from '../../app/classes/global';
import { ViewConsoleLog } from '../services/utilities/console_log';

export var CONSTANTS = {
  PSW_SECRETKEY: 'Fl3xxu5M0b1le'
};

export class Utils {
  /**
   * @method capitalize
   * @param  {string} string to capitalize
   * @return {string} capitalized string
   *  takes a string and transform it to lower case, except for the first character
   *  that is transformed to upper case
   */
  static capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  /**
   * @method toTitleCase
   * @param  {string} string to titlecase capitalize
   * @return {string} capitalize first letter of each word
   *  takes a string and transform it to lower case, except for the first letter of each word
   */
  static toTitleCase(str) {
    if (str != undefined)
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    else return '';
  }

  /**
   * @method encrypt
   * @param  {string} string to encrypt
   * @return {string} text encrypted
   *  takes a string and encrypted using the AES algorithm and a generic secret key
   */
  static encrypt(text) {
    let encrypt_text;
    try {
      encrypt_text = CryptoJS.AES.encrypt(text, CONSTANTS.PSW_SECRETKEY);
      return encrypt_text.toString();
    } catch (error) {
      return '';
    }
  }

  /**
   * @method decrypt
   * @param  {string} string to decrypt
   * @return {string} text decrypted
   *  takes a string and decrypted using the AES algorithm and a generic secret key
   */
  static decrypt(text) {
    let decript_text;
    try {
      decript_text = CryptoJS.AES.decrypt(text, CONSTANTS.PSW_SECRETKEY);
      return decript_text.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return '';
    }
  }

  /**
   * @method findElement
   * @param  {array} array of object
   * @param  {string} name of property
   * @param  {string} value of property to find
   * @return {any} item found
   * iterates an array looking for the value of the property and returns the item found
   */
  static findElement(arr, propName, propValue) {
    for (var i = 0; i < arr.length; i++)
      if (arr[i][propName] == propValue) return arr[i];
  }

  /**
   * @method removeElement
   * @param  {array} array of object
   * @param  {string} name of property
   * @param  {string} value of property to find
   * @return {array} array without the indicated element
   * iterates an array looking for the value of the specified property and removes
   */
  static removeElement(arr, propName, propValue) {
    for (var i = 0; i < arr.length; i++)
      if (arr[i][propName] == propValue) arr.splice(i, 1);

    return arr;
  }

  static paginate(page: Page) {
    return (
      'limit=' +
      (page.limit || 50).toString() +
      '&offset=' +
      (page.offset || 0).toString()
    );
  }

  static toStringParseFloat(value) {
    value = value.toString();
    value = parseFloat(value);

    return value;
  }

  static truncateDecimals(num, digits) {
    var numS = num.toString();
    var decPos = numS.indexOf('.');
    var substrLength = decPos == -1 ? numS.length : 1 + decPos + digits;
    var trimmedResult = numS.substr(0, substrLength);
    var finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

    // adds leading zeros to the right
    if (decPos != -1) {
      var s = trimmedResult + '';
      decPos = s.indexOf('.');
      var decLength = s.length - decPos;

      while (decLength <= digits) {
        s = s + '0';
        decPos = s.indexOf('.');
        decLength = s.length - decPos;
        substrLength = decPos == -1 ? s.length : 1 + decPos + digits;
      }
      finalResult = s;
    }
    return finalResult;
  }
  

  static consoleLogGlobal() {
    ViewConsoleLog.consoleLog('-- UTILS --');

    ViewConsoleLog.consoleLog('-- Global SERVER --');
    ViewConsoleLog.consoleLog(Global.SERVER);
    ViewConsoleLog.consoleLog('-- Global API_ENDPOINT --');
    ViewConsoleLog.consoleLog(Global.API_ENDPOINT_V);
    ViewConsoleLog.consoleLog('-- TOKEN --');
    ViewConsoleLog.consoleLog(Global.TOKEN);
  }
}
