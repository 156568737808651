
import { Global } 			 from '../classes/global';

export class Product{

id: string;
codigoproducto: string;
codigo_marca: string;
stocktotal: number;
stocktotaldeposito: number;
stockrealdeposito: number;
stock_to_display: number;
nombre: string;
categoria: string;
precioventa: number;
preciopromocion: number;
coeficiente_iva: number;
simbolomoneda: string;
cotizacionmoneda: number;
integration_id: number=null;
model_id: number=null;
destination_id: string=null;
id_relation: number=null;
unidadmedida: string;
descripcion_empaque: string;
foto: string;

    constructor(product:any){

        if (product){
            this.id = product.ID_ARTICULO;
            this.codigoproducto = product.CODIGO_PRODUCTO;
            this.codigo_marca = product.CODIGO_MARCA;
            this.nombre = product.NOMBRE;
            this.categoria = product.DESCRIPCION_CATEGORIA;
            this.coeficiente_iva = product.COEFICIENTEIVA;
            this.cotizacionmoneda = product.COTIZACIONMONEDA;
            this.precioventa = product.PRECIOVENTA;
            this.preciopromocion = product.PRECIOPROMOCION;
            this.simbolomoneda = product.SIMBOLOMONEDA;
            this.stocktotaldeposito = product.STOCKTOTALDEPOSITO;
            this.stocktotal = product.STOCKTOTAL;
            this.stockrealdeposito = product.STOCKREALDEPOSITO;
            this.unidadmedida = product.DESCRIPCION_UNIDADMEDIDA;
            this.descripcion_empaque = product.DESCRIPCION_EMPAQUE;
            this.foto = product.FOTO;

            switch (Global.typeOfStock) {
            case '0':
                this.stock_to_display = product.STOCKTOTAL;
                break;
            case '1':
                this.stock_to_display = product.STOCKTOTALDEPOSITO;
                break;
            case '2':
                this.stock_to_display = product.STOCKREALDEPOSITO;
                break;
            default:
                this.stock_to_display = product.STOCKTOTAL;
            }

            
        }     

    }
}
