import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { config_FlxIntegrate } from '../../models/config';

@Injectable()
export class SynchroallService {
    
    synchronizing: number = 0;
    currentUser: {};
    api_root_url: string;
    
    mode: string = config_FlxIntegrate.modeSinchroAll;

  constructor(private http: HttpClient) {
    this.api_root_url = config_FlxIntegrate.api_root_url;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.synchronizing = 0;

  }

   ngOnInit() {

    this.synchronizing = 0;

   }
  

  public synchroAll() {

    let url = this.api_root_url +'/sincro/id_service=' + this.currentUser['service_id'] + '&database=' + this.currentUser['cnn_relation_db'] + this.mode;
    
    this.synchronizing = 1; 


    this.runSynchroAll(url).subscribe (res =>{

        this.synchronizing = 0; 

    })
  }

  public runSynchroAll(url) {
    
  let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

  return this.http
    .get(url,
      { headers: apiHeader }
    )
}

}