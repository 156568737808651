import { Utils } from "../classes/utils";

export class PaymentMethod{
    paymentId:number;
    name:string;
    value:number;
    hiddenvalue:number;


    constructor(payment?:any){
        if(payment){
            this.paymentId = payment.CODIGOMULTIPLAZO;
            this.name = Utils.toTitleCase(payment.DESCRIPCION);
            this.value = payment.INTERES;
            this.hiddenvalue = payment.RECARGO;
        }
        else{
            this.paymentId = -1
			this.name = "default"
			this.value = 0;
			this.hiddenvalue = 0;
        }
        
    }
}