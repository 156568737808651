export class Translate {


    static translate(str: string) {                      

        var translate: string;
        str = str.toString();

         switch(str) {            

            case "out_of_stock":
               translate = "fuera de stock";                
               break;

            case "active":
               translate = "activo";                
               break;

            case "paused":
                translate = "pausado";                
                break;

            case "invalid_token":
                translate = "token inválido, vuelva a autenticar";                
                break;
            
            case "Validation error":
                translate = "Error de validación";                
                break;

            case "Stock of item should be more than 0.":
                translate = "El stock del artículo debe ser mayor a 0.";                
                break;

                
            default: { 
                translate = str; 
                break; 
                 }                   
        } 

        return translate;
    };

}