
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';


import { HttpClient, HttpHeaders } from '@angular/common/http';

//import { GetPublicIp } from '../../../services/utilities/get_public_ip'
declare let ClientIP: any;

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss']
})

export class TestsComponent implements OnInit {

  privateIP ;
  publicIP;

  constructor(private http: HttpClient) { 
    
    this.privateIP = ClientIP;

    this.http.get('https://api.ipify.org?format=json').subscribe( data => {
      this.publicIP = data['ip'];
    }) 


  }

  ngOnInit() {   

  }


  public GetPublicIP(){ 

  }

  public RefreshToken(){
    console.log("Refresh");

    let apiHeader = new HttpHeaders().set("Content-Type", "application/json");

    var body_array = {
     client_id: '3672130686948413',
     client_secret: 'NxNML45RGLytsGc0hn0dnNxCXJuSJ9XN',
      //client_id: '2189962971708303',
      //client_secret: 'sVu238Qnoi5e2OeUJV3rnq9zkVAOdtkz',
      grant_type: 'refresh_token',
      refresh_token: 'TTG-5c1bd26a11f1be0006d59a02-301852500'
    };
    
     this.http.post("https://api.mercadolibre.com/oauth/token",JSON.stringify(body_array))
     .subscribe( data => {
       console.log(data);
    }) 
     console.log("Refresh FIN");
  }

  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);
  }
}
