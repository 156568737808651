export class UserMeli {
    id: number;
    nickname: string;

    constructor(user?: any) {
		if (user) {
      this.id = user.id;
      this.nickname = user.nickname
		}
    }
    
}