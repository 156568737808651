export class ShippingMeli{

    state:string
    city:string
    zip_code:string

    street_name:string
    street_number:string
        
    constructor(shipping) {
        
        if(shipping && shipping.receiver_address){
            this.state = shipping.receiver_address.state.name
            this.city = shipping.receiver_address.city.name
            this.zip_code = shipping.receiver_address.zip_code
            this.street_name = shipping.receiver_address.street_name
            this.street_number = shipping.receiver_address.street_number    
        }
        
    }
}