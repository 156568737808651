export class RelationsItems{

id_relation: number;
integration_id: number;
model_id: number;
origin_id: string;
destination_id: string;

    constructor(relation:any){

        if (relation){
            this.id_relation = relation.id;
            this.integration_id = relation.integration_id;
            this.model_id = relation.model_id;
            this.origin_id = relation.origin_id;
            this.destination_id = relation.destination_id.toUpperCase();                       
        }

    }
}