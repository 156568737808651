export class Global {
  static MAX_LENGTH_LAST_FOUND: number = 5;

  static SERVER: string = localStorage.getItem("ip_api_flx");
  static DATABASE_CLIENT: string = localStorage.getItem("cnn_relation_db");


  static API_VERSION: string = "/v2";
  static API_ENDPOINT_V: string = localStorage.getItem("ip_api_flx")+Global.API_VERSION;
  static API_ENDPOINT: string = localStorage.getItem("ip_api_flx");
  static SHOW_PICS: boolean = true;
  static TOKEN: string = localStorage.getItem("flx_access_token");

  // PARAMETROS

  static SHOW_BUTTON_SINCROALL: boolean;


  // DATOS PARA APIFLX_Integration
  static API_ENDPOINT_INTEGRADOR: string =
    "https://devnode1.mitiendaonline.com:4000";
  static APIFLX_USER_LOGIN: string = "/userlogin";
  static typeOfStock: string; // Es el tipo de stock que usa el cliente 0=permanente 1=real

  static API_ENDPOINT_2: 'https://devnode1.mitiendaonline.com:4000';
  static VERSION: string = 'v2'; 
  static PORT: string;
  
  static addHttp(url) {
    if (!/^(f|ht)tps?:\/\//i.test(url)) url = "https://" + url;
    return url;
  }
}
