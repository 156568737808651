
import {map , catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class IpService {
  privateIP;
  publicIP;

  constructor(private http: HttpClient) {}
  //  Function :

  public getPublicIP() {
    return this.http.get("https://api.ipify.org?format=json").pipe(
    map(res=>res)
    ,catchError(this.handleError));
  }

  private handleError(error: any) {
    let errMsg: string;

    if (error.message) {
      errMsg = error.message;
    } else {
      errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error. Verifique que posee conexión a la red.";
    }
    return observableThrowError(errMsg);
  }
}
