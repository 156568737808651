import { Utils } from '../classes/utils';

export class Customer {  //Database fields
	id:           string; //CODIGOCLIENTE
	name:         string; //RAZONSOCIAL
	address:      string; //DIRECCION
	state_id:	  string;
	city:		  string;
	neighborhood: string;
	phone:        string; //TELEFONO
	cell_phone:   string;
	document_id:  string; //DOCUMENTO
	vat_number:	  string;
	notes:		  string;
	email:        string; //EMAIL
	zipcode:	  string;
	reference_id: string; //CODIGOPARTICULAR
	isActive:	  boolean;//ACTIVO
	cuit:		  string; //CUIT
	doc_to_show:  string;
	paymentMethod:number;
	sales_tax_group_id:string;
	

	constructor(customer?: any) {
		if (customer) {

			let phone:string="";
			let cell_phone:string="";
			
			this.id           = customer.CODIGOCLIENTE;
			this.reference_id = Utils.toTitleCase(customer.CODIGOPARTICULAR);
			this.name         = Utils.toTitleCase(customer.RAZONSOCIAL);	
			this.cuit         = customer.CUIT;	
			this.document_id  = "30000000"//customer.DOCUMENTO;	

			this.cuit=this.cuit.replace(/-/g,"").trim();
			this.document_id=this.document_id.replace(/-/g,"").trim();
			
			let checkphones:string = (customer.TELEFONO) ? Utils.toTitleCase(customer.TELEFONO): "";

			//phone = checkphones.split("-")[0] || "";
			cell_phone = checkphones.split("-")[1] ||"";

			phone = phone.replace(/Tel:/g,"");
			cell_phone = cell_phone.replace(/Cel:/g,"");

			this.phone = phone.trim();
			this.phone = phone;
			this.cell_phone = cell_phone.trim();		

			this.doc_to_show = this.checkDoc((this.cuit!="") ? this.cuit : this.document_id);

			
			this.email        = Utils.toTitleCase(customer.EMAIL);
			this.isActive     = (customer.ACTIVO == 1);			
			this.zipcode      = Utils.toTitleCase(customer.CP);
			this.address      = Utils.toTitleCase(customer.DIRECCION);
			
			this.neighborhood = Utils.toTitleCase(customer.BARRIO);
			this.city		  = Utils.toTitleCase(customer.LOCALIDAD);
			this.state_id	  = customer.CODIGOPROVINCIA;
			this.notes		  = (customer.COMENTARIOS) ? Utils.toTitleCase(customer.COMENTARIOS): "";
			this.paymentMethod= customer.CODIGOMULTIPLAZO;
	
		}
	}	
	private checkDoc(dni:any){		
		let aux:string = dni.replace(/\.000000/g,"");
		aux = aux.replace(/\./g,"");
		return aux;
	}
}